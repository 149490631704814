.item-activity-container {
  background: transparent;
  border-radius: 16px;
  color: white;
  box-shadow: 0 0 12px 0 rgba(157, 96, 212, 0.5) !important;
  font-family: LemonMilk-light !important;
  margin-bottom: 2rem;

  .item-activity-event {
    font-family: LemonMilk;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    /* identical to box height */

    color: rgba(255, 255, 255, 0.6);
  }
  .item-activity-price-wrapper {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    .item-activity-usd {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */

      color: rgba(255, 255, 255, 0.6);
    }
  }
  .icon-price {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .item-activity-price {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
  }
}
