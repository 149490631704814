.container {
  display: flex !important;
  flex-direction: column !important;
  justify-content: start !important;
  color: white !important;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 2rem;
  font-family: LemonMilk !important;

  .tabs {
    border-bottom: 1px solid transparent;
    border-image: linear-gradient(
      90.49deg,
      rgba(218, 50, 154, 0.4),
      rgba(52, 102, 169, 0.4)
    );
    border-image-slice: 1;
  }
  .MuiTab-root {
    color: white !important;
  }
  .MuiTabs-indicator {
    position: absolute;
    height: 2px;
    bottom: 0;
    width: 100%;
    -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background: linear-gradient(83.66deg, #3762a6 0%, #d9329a 100%), #ffffff;
    // border color
  }
}
.leaderboard-loader {
  width: 100%;
  height: 80rem;
  margin-top: 15rem !important;
}
