.listing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: LemonMilk !important;
  color: white;
  padding: 2rem;

  .listing-summary {
    margin-top: 0.6rem;
    display: flex;
    flex-direction: column;
    width: 100%;

    .const-text {
      margin-top: 0.5rem;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;

      color: rgba(255, 255, 255, 0.6);
    }
  }
  .listing-set-price {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    .offer-price {
      width: 70%;
    }
  }
  .offer-name-details-wrapper {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 30rem;
    align-items: center;

    .listing-token-details {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;

      color: rgba(255, 255, 255, 0.6);
    }
  }
  .offer-nft-name-icon-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .offer-nft-icon {
      filter: drop-shadow(3px 3px 50px #000000);
      border-radius: 16px;
    }
    .nft-name-collection-wrapper {
      display: flex;
      flex-direction: column;
      font-family: LemonMilk;
      margin-left: 1rem;

      .offer-nft-name {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
      }
      .offer-nft-collection {
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;

        color: rgba(255, 255, 255, 0.6);
      }
    }
  }

  .offer-price {
    margin-top: 1rem;
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    font-family: LemonMilk !important;
    color: #ffffff !important;
    width: 100%;
  }
  .offer-price::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50px;
    padding: 2px;
    background: linear-gradient(83.66deg, #3762a6 0%, #d9329a 100%), #ffffff;

    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  .offer-duration {
    width: 100%;
    margin-top: 2rem;
    .duration-value {
      margin-top: 1rem;
    }
  }
}
.divider {
  margin-top: 1rem;
  height: 1px;
  border-image: linear-gradient(
    90.49deg,
    rgba(218, 50, 154, 0.4),
    rgba(52, 102, 169, 0.4)
  );
  border-image-slice: 1;
  width: 100%;
}
