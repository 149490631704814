.collection-wrapper {
  display: flex;
  flex-direction: row;
  width: 20rem;
  align-items: center;
  font-family: LemonMilk-light;
  font-size: 12px !important;
  &:hover {
    cursor: pointer;
  }
  .icon {
    width: 20%;
  }
  .small-image {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    width: 4.25rem;
    height: 4.25rem;
    filter: drop-shadow(5px 5px 10px #000000);
    border-radius: 20px;
  }

  .volume-icon {
    display: flex;
    flex-direction: row;
    width: 30%;
    .volume {
      font-weight: 500;
      font-size: 10px;
      line-height: 24px;
      color: white;
    }
    margin-right: 2rem;
  }

  .name-floor-price-wrapper {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    width: 50%;

    .name {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: white;
    }
    .floor-price {
      color: white;
      font-size: 10px !important;
    }
  }
}
