@import "../../../styles/global.scss";

.nft-auction-details-container {
  box-sizing: border-box;
  align-items: center;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5) !important;
  border-radius: 10px;
  background-color: transparent;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .current-price-wrapper {
    display: flex;
    flex-direction: column;
    font-family: LemonMilk;
    margin-top: 1rem;

    .time-left-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1rem;

      .time-left-value {
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        margin-left: 0.5rem;
        color: rgba(255, 255, 255, 0.6);
      }
    }
    .current-price-const {
      color: rgba(255, 255, 255, 0.6);

      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
    }

    .current-price-eth-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;

      .current-price-eth-icon {
        margin-left: -0.5rem;
      }

      .current-price-eth {
        margin-left: 0.5rem;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 43px;

        color: #ffffff;
      }

      .current-price-usd {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
        margin-left: 0.5rem;

        /* identical to box height */

        text-align: right;

        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
  .actions-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
    .make-offer {
      width: 13rem;
      height: 3rem;
      background: transparent;
      border-radius: 16px;
      color: white;
      padding: 0.5rem 4rem !important;
      margin-top: 1rem;
      box-shadow: 0 0 8px 0 rgba(157, 96, 212, 0.5) !important;
      font-family: LemonMilk-light !important;
    }
    .buy-now {
      @extend .gradient-btn;
      width: 13rem;
      height: 3rem;
    }
    // .buy-now {
    //   padding: 20px 60px;
    //   border: none;
    //   outline: none;
    //   position: relative;
    //   border-radius: 5px;
    //   background: linear-gradient(to right, #00ffa3, #dc1fff);
    //   cursor: pointer;
    // }
    // .buy-now::before {
    //   content: "";
    //   position: absolute;
    //   top: 1px;
    //   right: 1px;
    //   bottom: 1px;
    //   left: 1px;
    //   background-color: rgb(17, 16, 50);
    //   border-radius: 4px;
    // }
    // .buy-now::after {
    //   content: attr(data);
    //   font-size: 16px;
    // }
  }
}
