@import "../../../styles/global.scss";

.offer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: LemonMilk !important;
  color: white;
  padding: 1.5rem;

  .offer-name-details-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 30rem;
    align-items: center;
    .offer-nft-name-icon-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .offer-nft-icon {
        filter: drop-shadow(3px 3px 50px #000000);
        border-radius: 16px;
        margin-right: 1rem;
      }
      .nft-name-collection-wrapper {
        display: flex;
        flex-direction: column;
        font-family: LemonMilk;
        margin-left: 0.5rem;

        .offer-nft-name {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
        }
        .offer-nft-collection {
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
          line-height: 16px;

          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  .offer-set-price {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    .offer-price {
      width: 70%;
      margin-right: 1rem;
    }
  }
  .offer-box-wrapper {
    margin-top: 1.5rem;
    border: 2px solid #1f3567;
    border-image: linear-gradient(
      90.49deg,
      rgba(218, 50, 154, 0.4),
      rgba(52, 102, 169, 0.4)
    );
    border-image-slice: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-weight: 400;

    font-size: 14px;
    line-height: 19px;

    .offer-box-wrapper-left {
      .offer-box-const {
        margin: 0.5rem;
      }
    }
    .offer-box-wrapper-right {
      .offer-value-box {
        margin: 0.5rem;
      }
    }
  }

  .offer-price {
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    font-family: LemonMilk !important;
    color: #ffffff !important;
    width: 100%;
  }
  .offer-price::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50px;
    padding: 2px;
    background: linear-gradient(83.66deg, #3762a6 0%, #d9329a 100%), #ffffff;

    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  .offer-duration {
    width: 100%;
    margin-top: 2rem;
    .duration-value {
      margin-top: 1rem;
    }
  }
}
.divider {
  margin-top: 1rem;
  height: 1px;
  border-image: linear-gradient(
    90.49deg,
    rgba(218, 50, 154, 0.4),
    rgba(52, 102, 169, 0.4)
  );
  border-image-slice: 1;
  width: 100%;
}
.gray-small-font {
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 12px !important;
  line-height: 16px !important;

  color: rgba(255, 255, 255, 0.6) !important;
}
.offer-summary {
  margin-top: 0.6rem;
  display: flex;
  flex-direction: column;
  width: 100%;

  .const-text {
    margin-top: 0.5rem;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;

    color: rgba(255, 255, 255, 0.6);
  }
}

.swap-eth-weth {
  background: linear-gradient(95.91deg, #3466a9 4.94%, #901662 98.57%);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 100px !important;
  color: white !important;
  font-weight: 600 !important;
  padding: 0.5rem 4rem !important;
  width: 60%;
  text-transform: uppercase;
  border: none !important;
  box-shadow: 0px 0px 26.9091px rgba(217, 50, 154, 0.2),
    0px 0px 26.9091px rgba(52, 102, 169, 0.5);
}
