.card-collection-skeleton {
  display: flex !important;
  flex-direction: row;
  padding: 0.5rem !important;
  justify-content: space-between;
  align-items: center;
  border-radius: 32px !important;
}
.left-col-collection {
  margin-right: 1rem !important;
  border-radius: 32px !important;
}
.card-skeleton .right-col {
  flex: 1 !important;
}
