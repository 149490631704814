.enable-weth-progress {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.confirm-order {
  display: flex;
  flex-direction: row;
  align-items: center;
}
