.description-container {
  font-family: LemonMilk-light !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  border-radius: 16px;
  box-sizing: border-box;
  background: transparent;
  color: white;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5) !important;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
