.collections-list {
  margin-top: 2rem;
  height: 100%;
  width: 100%;
  max-height: 40rem;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px !important;
    position: float !important;
    height: 5px !important;
  }
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }
}
