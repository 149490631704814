.icon-price {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .item-activity-price {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
}
