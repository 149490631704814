@import "../../styles/global.scss";

.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  color: white;
  font-family: LemonMilk-light;

  text-transform: uppercase !important;

  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 5rem;

    .title {
      width: 70%;
      margin-top: 2rem;
      font-weight: 600;
      font-size: 80px;
      font-family: LemonMilk;
    }
    .sub-title {
      width: 70%;
      margin: 2rem;
      font-weight: 400;
      font-size: 25px;
    }
    .buttons {
      display: flex;
      flex-direction: row;
      margin-bottom: 4rem;
      width: 40%;
    }
  }
  .logo {
    width: 50%;
  }

  .gradient-btn {
    background: linear-gradient(95.91deg, #3466a9 4.94%, #901662 98.57%);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
    border-radius: 100px !important;
    color: white !important;
    font-weight: 600 !important;
    padding: 0.5rem 4rem !important;
    width: 60%;
    text-transform: uppercase;
    border: none !important;
    box-shadow: 0px 0px 26.9091px rgba(217, 50, 154, 0.2),
      0px 0px 26.9091px rgba(52, 102, 169, 0.5);

    margin-right: 2rem;
  }

  .border-gradient-btn {
    border-radius: 100rem !important;
    font-size: 1rem !important;
    padding: 0.5rem 5rem !important;
    color: white !important;

    box-shadow: 0 0 12px 0 rgba(157, 96, 212, 0.5) !important;
    border: solid 3px transparent !important;
    margin-left: 2rem !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
  }
}
