@font-face {
  font-family: "LemonMilk";
  font-style: normal;
  font-weight: 400;
  src: url(./lemonmilk/LEMONMILK-Regular.otf) format("truetype");
}

@font-face {
  font-family: "LemonMilk-light";
  font-style: normal;
  font-weight: 300;
  src: url(./lemonmilk/LEMONMILK-Light.otf) format("truetype");
}

@font-face {
  font-family: "LemonMilk-bold";
  font-style: bold;
  font-weight: 900;
  src: url(./lemonmilk/LEMONMILK-Bold.otf) format("truetype");
}

// @font-face {
//   font-family: "Lato";
//   font-style: bold;
//   font-weight: 700;
//   src: local("Lato"), url(./lato/Lato-Bold.ttf) format("truetype");
// }

// @font-face {
//   font-family: "Lato";
//   font-weight: 900;
//   src: local("Lato"), url(./lato/Lato-Black.ttf) format("truetype");
// }
