.time-picker-button {
  color: white !important;
  border: none !important;
  opacity: 0.85;
  font-size: 17px;
}

.timer-picker-wrapper {
  float: right;
  border-color: #901662;
  font-family: LemonMilk, serif !important;
}

.timer-picker-box {
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 10px;
}
