.refresh-collection-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  .refresh-collection {
    box-sizing: border-box;
    width: 60px;
    height: 60px;
    background: linear-gradient(
      90.49deg,
      rgba(218, 50, 154, 0.2) 50%,
      rgba(52, 102, 169, 0.2) 93.29%
    );
    border-radius: 16px;
  }
  &:hover {
    background: linear-gradient(
      80.49deg,
      rgba(218, 50, 154, 0.2) 50%,
      rgba(52, 102, 169, 0.2) 93.29%
    );
    border-radius: 16px;
  }
}
