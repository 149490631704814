@import "../../styles/global.scss";

.home-wrapper {
  background: url("../../../public/learn-min.png");
  background-size: cover;
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;
}
.title-mobile {
  margin-top: 2rem;
  margin-left: 2rem;
  font-family: LemonMilk-bold;
  font-size: 48px;
  margin-left: 1rem;
  @extend .text-linear-title;
}
.small-title-mobile {
  margin-top: 2rem;
  margin-left: 2rem;
  font-family: LemonMilk-bold;
  font-size: 36px;
  margin-left: 1rem;
  @extend .text-linear-title;
}
