@import "../../../styles/global.scss";

.nft-details-container {
  @extend .containers-nft-page;

  .nft-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .nft-details-headers {
      font-family: LemonMilk;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      margin-left: 1rem;
      margin-right: 1rem;
      color: rgba(255, 255, 255, 0.6);

      .nft-details-header {
        margin-bottom: 1rem;
      }
    }
    .nft-details-data {
      margin-right: 2rem;
      .details-contract-address {
        margin-bottom: 1rem;

        font-family: LemonMilk;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;

        background: linear-gradient(
            83.66deg,
            rgba(55, 98, 166, 0.8) 0%,
            rgba(217, 50, 154, 0.8) 100%
          ),
          #ffffff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }

      .details-tokenid {
        margin-bottom: 1rem;
      }
      .details-erc {
        @extend .white-small;
        margin-bottom: 1rem;
      }
      .details-chain {
        @extend .white-small;
        margin-bottom: 1rem;
      }
    }
  }
}
