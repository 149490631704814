.loader-wrapper,
.loader-wrapper-with-background {
  display: flex;
  align-items: center;
  justify-content: center;

  --size: var(--size);
  --clr1: #fb86b6;
  --clr3: #6cadca;
  --clr5: #9090df;
}

.loader-wrapper-with-background {
  backdrop-filter: blur(50px);
  width: 100%;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (min-width: 600px) {
  .container {
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(3, auto);
  }
}

.spinner:nth-child(1) {
  --animation-duration: 650ms;
  position: relative;
  width: var(--size);
  height: var(--size);

  .spinner-item {
    position: absolute;
    width: var(--item-size);
    height: var(--item-size);
    top: calc(50% - var(--item-size) / 2);
    left: calc(50% - var(--item-size) / 2);
    border: 4px solid transparent;
    border-left: 4px solid var(--clr-spinner);
    border-right: 4px solid var(--clr-spinner);
    border-radius: 50%;
    animation: spinner2 var(--animation-duration) linear infinite;

    @keyframes spinner2 {
      to {
        transform: rotate(360deg);
      }
    }
  }

  .spinner-item:nth-of-type(1) {
    --item-size: var(--size);
    --clr-spinner: var(--clr1);
    border-top: 4px solid var(--clr1);
  }

  .spinner-item:nth-of-type(2) {
    --item-size: calc(var(--size) - 15px);
    --clr-spinner: var(--clr5);
    border-bottom: 4px solid var(--clr5);
  }

  .spinner-item:nth-of-type(3) {
    --item-size: calc(var(--size) - 30px);
    --clr-spinner: var(--clr3);
    border-top: 4px solid var(--clr3);
  }
}
