@import "../../styles/global.scss";

.top-collections-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 10%;
  margin-left: 10%;

  .title-linear {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    margin-top: 2rem;
    .title {
      color: white;
      margin-left: 1rem;
      margin-bottom: 2.5rem;
      font-size: 48px;
      line-height: 24px;
      font-family: LemonMilk-bold;
    }

    .top-collection-linear {
      @extend .text-linear;
      font-size: 26px;
    }
  }

  .top-collections {
    display: flex;
    flex-wrap: wrap;
  }
}
