@import "../../styles/global.scss";

.collection-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;

  .banner-icon-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .banner {
      background-repeat: no-repeat;
      background-size: auto;
      height: 50%;
      position: relative;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));

      .banner-image {
        position: relative;

        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
          inset 0px 0px 20px rgba(0, 0, 0, 0.8);
        width: 100%;
        height: 20rem;
      }

      .banner-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.1)
        );
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
          inset 0px 0px 20px rgba(0, 0, 0, 0.8);
        z-index: 2;
      }
    }
    .icon {
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
      .small-image {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        border: 2px solid rgba(255, 255, 255, 0.5);
        box-shadow: inset 0px 0px 50px rgba(0, 0, 0, 0.25);
        filter: drop-shadow(0px 0px 50px #000000);
        border-radius: 40px;
        width: 169px;
        height: 169px;
      }
    }
  }

  .collection-page-details {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .name-collection-page {
      color: white !important;
      font-weight: 200 !important;
      font-size: 36px !important;
      font-family: "LemonMilk";
    }

    .items-created-chain {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 2rem;
      width: 25%;

      .items-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .items {
          @extend .font-header-values;
        }
        .items-const {
          @extend .font-header-const;
        }
      }

      .chain-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-family: "LemonMilk-light";

        .chain {
          @extend .font-header-values;
        }
        .chain-const {
          @extend .font-header-const;
        }
      }

      .created-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .created {
          @extend .font-header-values;
        }

        .created-const {
          @extend .font-header-const;
        }
      }
    }
    .description {
      font-size: 16px;
      text-align: center;
      margin-top: 1rem;
      width: 40%;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      font-family: "LemonMilk-light";
    }
    .tech-details {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 2rem;
      width: 70%;
      font-family: "LemonMilk-light";

      .volume-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .volume {
          @extend .font-header-values;
        }

        .volume-const {
          @extend .font-header-const;
        }
      }

      .offer-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .offer {
          @extend .font-header-values;
        }
        .offer-const {
          @extend .font-header-const;
        }
      }

      .listed-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .listed {
          @extend .font-header-values;
        }
        .listed-const {
          @extend .font-header-const;
        }
      }
      .floor-price-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .floor-price {
          @extend .font-header-values;
        }
        .floor-price-const {
          @extend .font-header-const;
        }
      }
      .owners-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .owners {
          @extend .font-header-values;
        }
        .owners-const {
          @extend .font-header-const;
        }
      }
      .buttons {
        display: flex;
        flex-direction: row;

        .watch-collection-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          .watch-collection {
            box-sizing: border-box;
            width: 50px;
            height: 50px;
            background: linear-gradient(
              90.49deg,
              rgba(218, 50, 154, 0.2) 50%,
              rgba(52, 102, 169, 0.2) 93.29%
            );
            border-radius: 16px;
          }
          &:hover {
            background: linear-gradient(
              80.49deg,
              rgba(218, 50, 154, 0.2) 50%,
              rgba(52, 102, 169, 0.2) 93.29%
            );
            border-radius: 16px;
          }
        }

        .watched-collection {
          box-sizing: border-box;
          width: 50px;
          height: 50px;
          background: linear-gradient(
            80.49deg,
            rgba(218, 50, 154, 0.8) 30%,
            rgba(52, 102, 169, 0.8) 79.29%
          );
          border-radius: 16px;
        }

        .refresh-collection-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-right: 1rem;
          .refresh-collection {
            box-sizing: border-box;
            width: 50px;
            height: 50px;
            background: linear-gradient(
              90.49deg,
              rgba(218, 50, 154, 0.2) 50%,
              rgba(52, 102, 169, 0.2) 93.29%
            );
            border-radius: 16px;
          }
          &:hover {
            background: linear-gradient(
              80.49deg,
              rgba(218, 50, 154, 0.2) 50%,
              rgba(52, 102, 169, 0.2) 93.29%
            );
            border-radius: 16px;
          }
        }
      }
    }
  }
}
