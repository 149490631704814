@import "../../styles/global.scss";

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: LemonMilk;
  .logo-and-name {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .logo-name {
      font-family: LemonMilk-bold;
      font-size: 48px;
      margin-left: 1rem;
      @extend .text-linear-title;
    }
  }
  .explore {
    left: 0%;
    right: 11.19%;
    top: 30.43%;
    bottom: 28.99%;
  }
}

.venus-logo:hover {
  cursor: pointer;
}

.explore:hover {
  cursor: pointer;
}

.stats:hover {
  cursor: pointer;
}

.help:hover {
  cursor: pointer;
}

.account:hover {
  cursor: pointer;
}
