.empty-state-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100% !important;
  color: white !important;
  font-style: "LemonMilk" !important;
  justify-content: center;
}
