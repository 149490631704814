.text-linear {
  font-family: LemonMilk;
  background: linear-gradient(83.66deg, #3762a6 0%, #d9329a 100%), #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.text-linear-normal {
  background: linear-gradient(83.66deg, #3762a6 0%, #d9329a 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-family: LemonMilk;
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}

.text-big {
  font-family: LemonMilk;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 41px;
  /* identical to box height */

  color: #ffffff;
}

.text {
  font-family: LemonMilk;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  color: #ffffff;
}

.gradient-btn {
  background: linear-gradient(95.91deg, #3466a9 4.94%, #901662 98.57%);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 100px !important;
  color: white !important;
  font-weight: 600 !important;
  padding: 0.5rem 4rem !important;
  width: 100%;
  text-transform: uppercase;
  border: none !important;
  box-shadow: 0px 0px 26.9091px rgba(217, 50, 154, 0.2),
    0px 0px 26.9091px rgba(52, 102, 169, 0.5);
}

.gradient-btn-disabled {
  background: gray;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 100px !important;
  color: white !important;
  font-weight: 600 !important;
  padding: 0.5rem 4rem !important;
  width: 100%;
  text-transform: uppercase;
  border: none !important;
  box-shadow: 0px 0px 26.9091px rgba(217, 50, 154, 0.2),
    0px 0px 26.9091px rgba(52, 102, 169, 0.5);
}

.white-small {
  font-family: LemonMilk;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;

  color: #ffffff;
}

.containers-nft-page {
  font-family: LemonMilk-light !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  border-radius: 16px;
  box-sizing: border-box;
  background: transparent;
  color: white;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5) !important;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.font-header-const {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;

  opacity: 0.6;
  font-family: "LemonMilk-light";
}

.font-header-values {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;
  font-family: "LemonMilk";

  color: #ffffff;
}

.filters-explore {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  /* identical to box height, or 133% */
  font-family: "LemonMilk" !important;

  color: #ffffff !important;
}

.filters-text {
  font-family: "LemonMilk" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  /* identical to box height */

  color: rgba(255, 255, 255, 0.6);
}
.text-linear-title {
  background: linear-gradient(83.66deg, #3762a6 0%, #d9329a 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-family: LemonMilk;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 52px;
}

.button-with-linear {
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  font-family: LemonMilk;
  color: #ffffff;
}

.button-with-linear::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px;
  padding: 2px;
  background: linear-gradient(83.66deg, #3762a6 0%, #d9329a 100%), #ffffff;

  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.button-with-linear-big {
  position: relative;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  /* identical to box height */
  width: 20rem;
  font-family: LemonMilk;
  color: #ffffff;
}

.button-with-linear-big::before {
  content: "";
  position: absolute;
  text-align: center;

  width: 100%;
  inset: 0;
  border-radius: 50px;
  padding: 2px;
  background: linear-gradient(83.66deg, #3762a6 0%, #d9329a 100%), #ffffff;

  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.grey-font-usd {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  color: rgba(255, 255, 255, 0.6);
}

.text-linear-title-small {
  background: linear-gradient(83.66deg, #3762a6 0%, #d9329a 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-family: LemonMilk;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}
