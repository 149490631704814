.footer {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 10%;
  font-family: LemonMilk;
  color: white;
  align-items: center;

  .logo-name-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .name-footer {
    margin-left: 1rem;
    font-family: LemonMilk-bold;
    font-size: 20px;
  }

  .links-social-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 2rem;

    .links-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .explore {
        margin-right: 1rem;
      }
    }
  }
}
