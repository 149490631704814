.dialog-container {
  background: linear-gradient(83.66deg, #3762a6 0%, #d9329a 100%), #ffffff;
  border-radius: 16px;
  color: white !important;

  .dialog-wrapper {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.dialog-title {
  font-family: LemonMilk !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 27px !important;
  /* identical to box height */
  background: rgba(20, 13, 71, 1);
  color: #ffffff !important;
}
.dialog-terms {
  font-family: LemonMilk !important;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */

  display: flex;
  align-items: center;
  text-align: center;

  color: rgba(255, 255, 255, 0.6);
}
