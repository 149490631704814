input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiAutocomplete-listbox {
  &::-webkit-scrollbar {
    width: 6px !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px !important;
    position: float !important;
    height: 5px !important;
  }
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }
}
.MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
  // position: absolute;
  // padding: 1px;
  // background: linear-gradient(83.66deg, #3762a6 0%, #d9329a 100%), #ffffff;
  // -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  // -webkit-mask-composite: xor;
  // mask-composite: exclude;
  // border-radius: 50px;

  //   z-index: 2;
}
.MuiOutlinedInput-notchedOutline::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 150px;
  padding: 2px;
  background: linear-gradient(83.66deg, #3762a6 0%, #d9329a 100%), #ffffff;

  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
.MuiAutocomplete-popper {
  width: 42rem !important;
  max-width: max-content;
}
.MuiAutocomplete-clearIndicator {
  display: none;
}

.MuiAutocomplete-listbox {
  background: #140d47 !important;
}

.items-search-wrapper {
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: row;
  width: 40rem;
  background: #140d47;
  color: white;
  font-family: LemonMilk;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
  &::-webkit-scrollbar {
    width: 6px !important;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px !important;
    position: float !important;
    height: 5px !important;
  }
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }

  .image-url-search {
    width: 15%;
    margin-top: 1rem;
    margin-right: 1rem;
    .image-search {
      width: 3.375rem;
      height: 3.375rem;
      filter: drop-shadow(5px 5px 10px #000000);
      border-radius: 20px;
      margin-left: 1.5rem;
    }
  }

  .name-and-items {
    width: 60%;
    display: flex;
    flex-direction: column;
    .search-name {
      font-family: "LemonMilk-Bold";
      font-size: 14px;
      line-height: 14px;
    }
    .search-items {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

      color: #ffffff;
    }
  }

  .eth-price {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .border-search {
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
}
