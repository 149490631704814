.offer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: LemonMilk !important;
  color: white;

  .offer-name-details-wrapper {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 30rem;
    align-items: center;
  }

  .offer-nft-name-icon-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .offer-nft-icon {
      filter: drop-shadow(3px 3px 50px #000000);
      border-radius: 16px;
    }

    .nft-name-collection-wrapper {
      display: flex;
      flex-direction: column;
      font-family: LemonMilk;
      margin-left: 0.5rem;

      .offer-nft-name {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
      }

      .offer-nft-collection {
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;

        color: rgba(255, 255, 255, 0.6);
      }
    }
  }

  .offer-box-wrapper {
    margin-top: 1.5rem;
    border: 2px solid #1f3567;
    border-image: linear-gradient(
      90.49deg,
      rgba(218, 50, 154, 0.4),
      rgba(52, 102, 169, 0.4)
    );
    border-image-slice: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-weight: 400;

    font-size: 14px;
    line-height: 19px;

    .offer-box-wrapper-left {
      .offer-box-const {
        margin: 0.5rem;
      }
    }

    .offer-box-wrapper-right {
      .offer-value-box {
        margin: 0.5rem;
      }
    }
  }

  .offer-price {
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */
    font-family: LemonMilk !important;
    color: #ffffff !important;
    width: 100%;
  }

  .offer-price::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50px;
    padding: 2px;
    background: linear-gradient(83.66deg, #3762a6 0%, #d9329a 100%), #ffffff;

    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  .offer-duration {
    width: 100%;
    margin-top: 2rem;

    .duration-value {
      margin-top: 1rem;
    }
  }
}
