@import "../../../styles/global.scss";

.nft-listing-container {
  @extend .containers-nft-page;

  .nft-listing {
    display: flex;
    flex-direction: column;
    .nft-listing-data {
      display: flex;
      flex-direction: row;
      margin-left: 2rem;
      align-items: center;
      justify-content: center;

      .listing-data {
        width: 20%;
      }
    }
    .nft-listing-headers {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid rgba(52, 102, 169, 0.2);

      .nft-listing-header {
        width: 20%;
        margin-bottom: 0.5rem;
        margin-right: 2rem;
        margin-left: 1rem;
      }
    }
  }
}
