.nft-card-wrapper {
  width: 14rem;
  height: 16rem;

  border: 1px solid rgba(255, 255, 255, 0.25);
  position: relative;
  margin-left: 1rem;
  margin-bottom: 1rem;
  border-radius: 32px;

  .nft-card-image {
    width: 100%;
    height: 100%;
    border-radius: 32px;

    .nft-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 32px;
    }

    .image-overly {
      background: linear-gradient(
        180deg,
        rgba(29, 11, 98, 0) 16.63%,
        rgba(29, 11, 98, 0.9) 87%
      );
      border-radius: 32px;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      border-radius: 32px;
    }

    .hovered-card {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.15);
      backdrop-filter: blur(10px);
      z-index: 100;
      border-radius: 32px;
      top: 0;
    }
  }

  .nft-card-details {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 1rem;
    top: 10rem;
    z-index: 300;

    .nft-card-token-name {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: LemonMilk-bold;
    }

    .nft-card-details-price-buy-time {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left-nft-card-details-price-buy-time {
        .nft-card-price-wrapper {
          margin-top: 0.5rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          .card-price-const {
            font-family: LemonMilk;
            font-style: normal;
            font-weight: 300;
            font-size: 11px;
            line-height: 15px;
            margin-right: 0.5rem;
            /* identical to box height */

            color: #ffffff;
          }
          .nft-card-price {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }

      .right-nft-card-details-price-buy-time {
        .nft-add-cart {
          margin-top: 0.5rem;
        }
      }
    }

    .nft-card-time {
      margin-top: 0.3rem;
      font-family: LemonMilk;
      font-style: normal;
      font-weight: 300;
      font-size: 11px;
      line-height: 15px;
      margin-right: 0.5rem;
    }
  }
}
