.container-collections-stats {
  display: flex !important;
  flex-direction: column !important;
  justify-content: start !important;
  color: white !important;
  margin-left: 16.875rem;
  margin-right: 16.875rem;
  margin-top: 2rem;
  font-family: LemonMilk serif !important;

  .MuiTab-root {
    color: white !important;
  }
  .MuiTabs-indicator {
    position: absolute;
    height: 2px;
    bottom: 0;
    width: 100%;
    -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background: linear-gradient(83.66deg, #3762a6 0%, #d9329a 100%), #ffffff;
    // border color
  }
}
