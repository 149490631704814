.collection-wrapper {
  backdrop-filter: blur(2px);
  border-radius: 20px;

  .collection-display {
    display: flex;
    flex-direction: column;
    .collection-name {
    }
    .collection-creator {
    }
    .collection-desc {
    }
  }
}

.collection-image-cell {
  display: flex;

  &:hover {
    cursor: pointer;
  }
}
