.MuiTableRow-root th,
.MuiTableRow-root td {
  background-color: transparent;
  color: white;
  font-family: LemonMilk, serif !important;
  border-color: transparent;
  text-align: center;
}

.MuiTableRow-root th {
  opacity: 0.5;
  border-color: #901662;
  border-width: 2px;
  max-width: 100px;
}

.collection-image-cell {
  align-items: center;
}

.collection-image {
  max-width: 50px;
  border-radius: 10px;
  margin-right: 15px;
}

.show-more-gradient-btn {
  background: linear-gradient(95.91deg, #3466a9 20%, #901662 95%);
  border-radius: 80px !important;
  color: white !important;
  font-weight: 600 !important;
  padding: 0.5rem 2rem !important;
  width: 20%;
  text-transform: uppercase;
  border: none !important;
  opacity: 0.85;
  font-size: 14px;
}
