.swap-container {
  font-family: LemonMilk !important;

  .swap-sumamry {
    margin-top: 2rem;
  }
  .divider {
    margin-top: 2rem;
    margin-bottom: 2rem;
    height: 1px;
    border-image: linear-gradient(
      90.49deg,
      rgba(218, 50, 154, 0.4),
      rgba(52, 102, 169, 0.4)
    );
    border-image-slice: 1;
    width: 100%;
  }
  .swap-convert-from-to {
    .convert-const {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */
      color: #ffffff !important;
      margin-bottom: 0.3rem;
    }
    .balance {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 0.8rem;

      color: rgba(255, 255, 255, 0.6);
    }

    .input-amount-eth-weth {
      position: relative;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */
      font-family: LemonMilk !important;
      color: #ffffff !important;
      width: 100%;
    }
    .input-amount-eth-weth::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 50px;
      padding: 2px;
      background: linear-gradient(83.66deg, #3762a6 0%, #d9329a 100%), #ffffff;

      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }
}
