@import "../../../styles/global.scss";

.nft-container {
  display: flex;
  flex-direction: column;
  margin-right: 10%;
  margin-left: 10%;
  margin-top: 4rem;

  .nft-right-left-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;

    .nft-left-side {
      width: 45%;
      top: 0;

      .hovered-token-details {
        position: absolute;
        max-width: 100%;
        height: auto;
        padding: 0;
        margin: 0;
        background: rgba(255, 255, 255, 0.15);
        backdrop-filter: blur(10px);
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        top: 0;
      }

      .nft-image-wrapper {
        .nft-image {
          max-width: 100%;
          height: auto;
          padding: 0;
          margin: 0;

          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0.2)
          );
          border-radius: 16px;
        }
      }
      .nft-description {
        margin-top: 1rem;
        font-family: "LEMON MILK";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
    }
    .nft-right-side {
      width: 45%;
      .nft-name-buttons {
        display: flex;
        flex-direction: row;
        margin-bottom: 2rem;
        .nft-name-wrapper {
          display: flex;
          flex-direction: column;

          .nft-collection-name {
            @extend .text-linear-title;
            margin-bottom: 1rem;
          }
          .nft-name {
            @extend .text-big;
            margin-bottom: 1rem;
          }
          .nft-owner-wrapper {
            display: flex;
            flex-direction: row;
            .nft-owner {
              @extend .text;
              margin-right: 0.5rem;
            }
            .nft-owner-address {
              @extend .text-linear-normal;
            }
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
