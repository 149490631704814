.card-skeleton {
  display: flex !important;

  flex-direction: column;
  padding: 0.5rem !important;
  width: 14rem;
  height: 16rem;

  border: 1px solid rgba(255, 255, 255, 0.25) !important;
  position: relative;
  margin-left: 1rem;
  margin-bottom: 1rem;
  border-radius: 32px !important;
}
.card-skeleton .left-col {
  margin-right: 1rem !important;
  border-radius: 32px !important;
}
.card-skeleton .right-col {
  flex: 1 !important;
  border-radius: 32px !important;
}
