.properties-box {
  background: linear-gradient(
    360deg,
    rgba(119, 153, 226, 0.15) 3.33%,
    rgba(218, 50, 154, 0.15) 100%
  );
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  width: 11rem;
  height: 6rem;
  align-items: center;
  justify-content: center;
  margin: 1rem;

  .property-trait {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;

    background: linear-gradient(
        83.66deg,
        rgba(55, 98, 166, 0.7) 0%,
        rgba(217, 50, 154, 0.7) 100%
      ),
      #ffffff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .property-rarity {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    color: #ffffff;
  }
  .property-count {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;

    color: rgba(255, 255, 255, 0.6);
  }
}
