@import "../../styles/global.scss";
.MuiInputBase-input {
  margin-top: 0.4rem;
}
.nft-explore {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1rem;
  font-weight: 400;
  color: white !important;
  width: 100%;

  .filter-and-nfts {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .search-and-filters {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    justify-content: space-between;
    width: 100%;
    .box {
      position: relative;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */
      font-family: LemonMilk;
      color: #ffffff;
    }
    .box::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 50px;
      padding: 2px;
      background: linear-gradient(83.66deg, #3762a6 0%, #d9329a 100%), #ffffff;

      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }
  .filters {
    height: 100%;
    width: 25%;

    .filter-header {
      @extend .filters-explore;
      background: transparent;
    }
    .filters-text {
      // display: flex;
      // flex-direction: row;
      // align-items: left;
      // justify-content: space-between;
      @extend .filters-text;
    }
    .divider {
      color: white !important;
      height: 1px;
      background: rgba(196, 196, 196, 0.5);
    }

    .min-max {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: white !important;
    }
  }

  .nft-list {
    height: 100%;
    width: 100%;
    max-height: 50rem;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px !important;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px !important;
      position: float !important;
      height: 5px !important;
    }
    .MuiPaper-elevation1 {
      box-shadow: none !important;
    }
  }
}
.MuiInputBase-root {
  color: white !important;
}
