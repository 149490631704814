.collection-card-wrapper {
  width: 14rem;
  height: 16rem;
  border: 1px solid rgba(255, 255, 255, 0.25);
  position: relative;
  margin-left: 1rem;
  margin-bottom: 1rem;
  border-radius: 32px;

  .collection-card-image {
    width: 100%;
    height: 100%;
    border-radius: 32px;

    .my-collection-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 32px;
    }

    .image-overly {
      background: linear-gradient(
        180deg,
        rgba(29, 11, 98, 0) 16.63%,
        rgba(29, 11, 98, 0.9) 87%
      );
      border-radius: 32px;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      top: 0;
      border-radius: 32px;
    }
  }

  .collection-card-details {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 1rem;
    top: 12rem;
    z-index: 2;

    .collection-card-token-name {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: LemonMilk-bold;
    }
  }
}
