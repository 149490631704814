@import "../../../styles/global.scss";
.nft-price-container {
  @extend .containers-nft-page;

  .nft-price-history {
    height: 20rem !important;
  }

  .custom-tooltip {
    background: transparent;
  }
}
