.user-wrapper {
  display: flex;
  flex-direction: column;
  .user-name {
    margin-bottom: 4rem;
  }
}
.nft-list-owned {
  margin-top: 2rem;
}
